var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout-auth',[_c('div',{staticClass:"card card-outline card-navy",staticStyle:{"width":"clamp(300px, 350px, 500px)"}},[_c('div',{staticClass:"card-header text-center py-5"},[_c('img',{attrs:{"src":require("@/assets/logo.svg"),"width":"90","alt":""}})]),_c('div',{staticClass:"card-body text-left"},[_c('p',{staticClass:"login-box-msg"},[_vm._v("Sign in to start your session")]),_c('v-form',{ref:"initialReport",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{},[_c('v-text-field',{attrs:{"label":"username","dense":"","outlined":"","rules":[
              (value) => {
                return _vm.genericRequiredRule(value, 'username');
              },
            ]},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('div',{},[_c('v-text-field',{attrs:{"label":"password","dense":"","outlined":"","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"rules":[
              (value) => {
                return _vm.genericRequiredRule(value, 'password');
              },
            ]},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-btn',{staticClass:"btn bg-navy btn-block",attrs:{"type":"submit","loading":_vm.isLoading}},[_vm._v(" Sign In ")])],1)])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }